import MainPot from 'components/MainPot';
import useTableMainPot from 'tableCoordinateResolvers/useTableMainPot';
import PositioningContainer from './components/PositioningContainer';

const MainPotRenderer = () => {
    const coords = useTableMainPot();

    if (!coords) {
        return null;
    }

    return (
        <PositioningContainer position={coords}>
            <MainPot />
        </PositioningContainer>
    );
};

export default MainPotRenderer;
