import { GamePhase, getPlayer } from '@magicyard/poker-game/src/Game';
import { compareHands } from '@magicyard/poker-game/src/Hand';
import { useGameContext } from 'context/GameContext';
import { useEffect, useMemo, useState } from 'react';
import usePrevious from 'utils/hooks/usePrevious';

const useWinners = () => {
    const {
        G: {
            table: { pots },
            players,
            winnerIDs,
        },
        ctx: { phase },
    } = useGameContext();

    const prevPhase = usePrevious(phase);
    const [isFirstRun, setIsFirstRun] = useState(false);
    const [winnerIDsByHeirarchy, setWinnerIDsByHeirarchy] = useState([]);

    const winningPots = useMemo(() => {
        /* 
            if there's only one pot, show it.
            A single pot might exist with a single playerID if everyone folded.
            filter out pots with only one playerID ("refund" pots).
            */
        return pots.length > 1
            ? pots.filter((pot) => pot.playerIDs.length > 1)
            : pots;
    }, [pots]);

    useEffect(() => {
        if (
            prevPhase === GamePhase.AnnounceWinners &&
            phase === GamePhase.Hand
        ) {
            setWinnerIDsByHeirarchy([]);
        }
    }, [phase, prevPhase]);

    const shouldCalculateWinners = useMemo(() => {
        const isCorrectPhaseOrRefresh =
            !isFirstRun ||
            (prevPhase === GamePhase.Hand &&
                phase === GamePhase.AnnounceWinners);
        // We need to calculate winningPots[0].winnerIDs because Game.ts follows a mutable paradigm
        // and so it is not properly tracked here
        // which leads to errors after Game.ts cleanup routines
        return (
            isCorrectPhaseOrRefresh &&
            winnerIDs.length &&
            !!winningPots.length &&
            !!winningPots[0] &&
            !!winningPots[0].winnerIDs
        );
    }, [phase, winnerIDs, winningPots, prevPhase, isFirstRun]);

    useEffect(() => {
        if (shouldCalculateWinners) {
            setIsFirstRun(true);
            // used to filter winners which were already placed higher
            const uniqueWinnerIDs: { [key: string]: number } = {};

            /* 
            if there's only one pot, show it.
            A single pot might exist with a single playerID if everyone folded.
            filter out pots with only one playerID ("refund" pots).
         */
            const res = winningPots
                .map((winningPot) =>
                    winningPot.winnerIDs.filter((winnerID) => {
                        const isUniqueWinner = !uniqueWinnerIDs[winnerID];

                        uniqueWinnerIDs[winnerID] = 1;

                        return isUniqueWinner;
                    })
                )
                /*
                filter out the winning sidepots which have no winnerIDs,
                this can happen because only unique winners are allowed and if there's the same winner for
                2 or more sidepots then it's going to end up empty.
                */
                .filter((winnerIDs) => !!winnerIDs.length)
                // sort by best hand
                .sort((winnerIDsA, winnerIDsB) => {
                    return (
                        -1 *
                        compareHands(
                            getPlayer({players}, winnerIDsA[0]).bestHand,
                            getPlayer({players}, winnerIDsB[0]).bestHand
                        )
                    );
                });
            setWinnerIDsByHeirarchy(res);
        }
    }, [
        players,
        shouldCalculateWinners,
        winningPots,
        setIsFirstRun,
        isFirstRun,
    ]);

    return winnerIDsByHeirarchy;
};

export default useWinners;
