import styled from 'styled-components';

const Img = styled.img`
    position: absolute;
    z-index: 3;
    bottom: 0.3vw;
    left: 0vw;
    width: 8.8vw;
`;

const Plant = () => {
    return <Img src="/images/decor/plant.gif" />;
};

export default Plant;
