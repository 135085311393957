import { FC, useMemo, useRef } from 'react';
import { Volume as VolumeIcon } from '@magicyard/poker-components/Icon';
import { motion, Variants } from 'framer-motion';

// Style
import styled from 'styled-components/macro';
import { Card, Stack } from '@magicyard/poker-components';
import { Label, LabelContainerTypes } from './labels/ActionLabels';
import { NameLabel } from './labels/NameLabel';
import { CardsDecorLabel } from './labels/CardsDecorLabel';
import { ICard } from '@magicyard/poker-game/src/Cards';
import WinnerEffectAnimation from 'components/animations/WinnerEffect';
import { useGameContext } from 'context/GameContext';
import { GamePhase } from '@magicyard/poker-game/src/Game';
// import { CountdownCircleTimer } from 'react-countdown-circle-timer';

export enum PlayState {
    InActive = 'inActive',
    Bet = 'bet',
    Raise = 'raise',
    Fold = 'fold',
    Check = 'check',
    AllIn = 'allIn',
    Call = 'call',
    Playing = 'playing',
    WaitingForFirstMove = 'waitingForFirstMove',
}

export enum AnnouncementState {
    Winner = 'winner',
    Loser = 'loser',
}

export enum DealerState {
    SmallBlind = 'smallBlind',
    BigBlind = 'bigBlind',
    Dealer = 'dealer',
    None = 'none',
}

const IconWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: ${(props) => props.theme.font.size.l};
    height: ${(props) => props.theme.font.size.l};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    background: ${(props) => props.theme.palette.white};
    border-radius: 50%;
    opacity: 0.8;
`;

const AvatarWrapper = styled(motion.div)`
    position: relative;
    padding: 1rem;

    & div[aria-label='Countdown timer'] {
        position: absolute !important;
        z-index: 1;
    }
`;

const AvatarContainer = styled.div`
    max-width: 8vw;
    position: relative;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
`;

const SContainer = styled(motion.div)`
    position: relative;
    height: 6.1vw;
    width: 6.1vw;
    border-radius: 50%;
    border-style: solid;
    border-width: 4px;
    box-sizing: content-box;
`;

const StyledAvatar = styled.img`
    height: 100%;
    width: 100%;
    border-radius: inherit;
`;

interface IAvatar {
    src?: string;
    isTalking?: boolean;
    random?: boolean;
    name: string;
    value: number;
    isTransparent?: boolean;
    isHighlighted?: boolean;
    playState: PlayState;
    dealerState?: DealerState;
    cards: ICard[];
    announcementState: AnnouncementState;
    showCards: boolean;
    winningCards: number[];
}

enum AnimationVariants {
    Transparent = 'transparent',
    Highlighted = 'highlighted',
    Regular = 'regular',
    Active = 'active',
    Inactive = 'inactive',
}

const mainAnimationVariants: Variants = {
    [AnimationVariants.Transparent]: {
        // Folded
        opacity: 0.5,
    },
    [AnimationVariants.Highlighted]: {
        // When the player has won
        scale: 1.2,
        y: -20,
    },
    [AnimationVariants.Active]: {
        // When the player is active
        scale: 1.3,
        y: -20,
    },
};

const WinnerImg = styled.img`
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    margin: auto;
    width: 10rem;
    height: auto;
    transform: scale3d(1.7, 1.7, 1);
`;

const DealerIndication = styled.img`
    position: absolute;
    bottom: -8%;
    left: 70%;
    margin: auto;
    width: 6rem;
    height: auto;
    z-index: 11;
`;

const CARD_IMG_HEIGHT = 328;
const CARD_IMG_WIDTH = 233;
const CARD_ASPECT_RATIO = CARD_IMG_WIDTH / CARD_IMG_HEIGHT;
const CARDS_CONTAINER_WIDTH_REM = 10.5;
const CARD_WIDTH_PERCENT = 50;
const CARD_WIDTH_REM = (CARD_WIDTH_PERCENT / 100) * CARDS_CONTAINER_WIDTH_REM;
const CARD_HEIGHT_REM = CARD_WIDTH_REM / CARD_ASPECT_RATIO;

const RevealCardsContainer = styled.div`
    position: absolute;
    right: 60%;
    top: 15%;
    z-index: 10;
    width: 10.5rem;
    display: flex;
    ${Card} {
        width: ${CARD_WIDTH_REM}rem;
        height: ${CARD_HEIGHT_REM}rem;
    }
`;

const StyledAvatarCard = styled(Card)<{ isFirst?: boolean }>`
    // These look unused because of RevealCardsContainer above
    height: auto;
    //
    transform: ${(p) => (p.isFirst ? 'rotate(-10deg)' : 'rotate(10deg)')};
    position: relative;
    right: ${(p) => (p.isFirst ? '0' : '2rem')};
`;

// image animation only
const imageAnimationVariants: Variants = {
    [AnimationVariants.Active]: {
        boxShadow: '0px 0px 35px 30px #ffffff',
        borderColor: 'rgba(255, 255, 255, 0.4)',
        // borderColor: 'rgba(9, 220, 155, 1)',
        background: 'rgba(255, 255, 255)',
    },
    [AnimationVariants.Highlighted]: {
        boxShadow: '0px 0px 12px 7px #ffffff',
        borderColor: 'rgba(255, 255, 255, 0.4)',
        // borderColor: 'rgba(9, 220, 155, 1)',
        background: '#ffffff',
    },
    [AnimationVariants.Inactive]: {
        borderColor: 'rgba(255, 255, 255, 0.4)',
        background: 'rgba(255, 255, 255, 0.4)',
    },
};

// const CircleTimer = ({ size, isActive }) => {
//     return (
//         <>
//             {size && isActive && (
//                 <CountdownCircleTimer
//                     size={size}
//                     strokeWidth={15}
//                     isPlaying
//                     duration={30}
//                     colors={[
//                         ['#09dc9b', 0.33],
//                         ['#F7B801', 0.33],
//                         ['#A30000', 0.33],
//                     ]}
//                 />
//             )}
//         </>
//     );
// };

const Avatar: FC<IAvatar> = ({
    src,
    isTalking,
    random,
    name,
    value,
    playState,
    dealerState,
    announcementState,
    cards,
    showCards,
    winningCards,
}) => {
    const randomSrc = useMemo(
        () => `https://i.pravatar.cc/174?${Math.random()}`,
        []
    );

    const {
        ctx: { phase },
    } = useGameContext();

    const containerRef = useRef<HTMLDivElement>();

    // const containerPixelWidth = containerRef.current?.getBoundingClientRect()
    //     .width;

    const isWinPhase = phase === GamePhase.AnnounceWinners;

    const isActive = playState === PlayState.Playing && !isWinPhase;

    const isDealer = dealerState === DealerState.Dealer;

    const mainAnimationState = useMemo(() => {
        return announcementState === AnnouncementState.Winner
            ? AnimationVariants.Highlighted
            : playState === PlayState.Fold || playState === PlayState.InActive
            ? AnimationVariants.Transparent
            : isActive
            ? AnimationVariants.Active
            : '';
    }, [playState, announcementState, isActive]);

    const imageAnimationState = useMemo(() => {
        return announcementState === AnnouncementState.Winner
            ? AnimationVariants.Highlighted
            : isActive
            ? AnimationVariants.Active
            : AnimationVariants.Inactive;
    }, [isActive, announcementState]);

    const renderActionLabel = () => {
        const labelType =
            playState === PlayState.Call
                ? LabelContainerTypes.Call
                : playState === PlayState.Bet
                ? LabelContainerTypes.Bet
                : playState === PlayState.Raise
                ? LabelContainerTypes.Raise
                : playState === PlayState.Check
                ? LabelContainerTypes.Check
                : playState === PlayState.AllIn
                ? LabelContainerTypes.AllIn
                : playState === PlayState.Fold
                ? LabelContainerTypes.Fold
                : null;
        if (!labelType) {
            return null;
        }

        return <Label type={labelType} />;
    };

    const isLosingCard = (cardIndex: number) => {
        if (!winningCards.length) {
            // no one has won yet
            return undefined;
        }

        if (!isWinPhase) {
            // should not display winning cards, so no card is a losing card yet
            return false;
        }

        // this is the win phase, should display winning cards

        if (announcementState !== AnnouncementState.Winner) {
            // this guy didn't win, all of his cards are losing cards
            return true;
        }

        // this guy won

        const isWinningCard = winningCards.includes(cardIndex);

        if (isWinningCard) {
            // this is a winning card
            return false;
        }

        // this is a losing card
        return true;
    };

    return (
        <AvatarWrapper
            animate={mainAnimationState}
            variants={mainAnimationVariants}
        >
            <AvatarContainer ref={containerRef}>
                <NameLabel>{name}</NameLabel>
                {renderActionLabel()}
                {showCards &&
                playState !== PlayState.Fold &&
                cards.length &&
                cards[0]?.fileName &&
                cards[1]?.fileName ? (
                    <RevealCardsContainer>
                        <StyledAvatarCard
                            shadow={true}
                            fileName={cards[0].fileName}
                            isFirst={true}
                            isTransparent={isLosingCard(0)}
                        />
                        <StyledAvatarCard
                            shadow={true}
                            fileName={cards[1].fileName}
                            isTransparent={isLosingCard(1)}
                        />
                    </RevealCardsContainer>
                ) : (
                    <CardsDecorLabel />
                )}
                {isDealer && (
                    <DealerIndication src="/images/dealer.png" alt="" />
                )}
                {/* <CircleTimer size={containerPixelWidth} isActive={isActive} /> */}
                <SContainer
                    animate={imageAnimationState}
                    variants={imageAnimationVariants}
                >
                    <WinnerEffectAnimation
                        show={announcementState === AnnouncementState.Winner}
                    />
                    <StyledAvatar src={random ? randomSrc : src || ''} />
                </SContainer>
                <Stack value={value} />
                {isTalking && (
                    <IconWrapper>
                        <VolumeIcon />
                    </IconWrapper>
                )}
            </AvatarContainer>
            {announcementState === AnnouncementState.Winner && (
                <WinnerImg src="/images/winner.gif" alt="" />
            )}
        </AvatarWrapper>
    );
};

export default Avatar;
