import { Coordinates } from './types';
import { SeatPositions } from './SeatPositions';

const Circle = (center: Coordinates, radius: number) => {
    return (radians: number): Coordinates => ({
        x: center.x + radius * Math.cos(radians),
        y: center.y + radius * Math.sin(radians),
    });
};

export const createSeats = (
    rect: DOMRect,
    leftCircleRadius: number,
    rightCircleRadius: number
) => {
    const circleLeft = Circle(
        {
            x: rect.left + rect.width * 0.27,
            y: rect.top + rect.height * 0.5,
        },
        leftCircleRadius
    );
    const circleRight = Circle(
        {
            x: rect.left + rect.width * 0.73,
            y: rect.top + rect.height * 0.5,
        },
        rightCircleRadius
    );

    const circleMiddle = Circle(
        {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
        },
        leftCircleRadius
    );

    return {
        [SeatPositions.LeftCircleTop]: circleLeft(Math.PI * 1.5),
        [SeatPositions.LeftCircleTopLeft]: circleLeft(Math.PI * 1.25),
        [SeatPositions.LeftCircleLeft]: circleLeft(Math.PI * 1),
        [SeatPositions.LeftCircleBottomLeft]: circleLeft(Math.PI * 0.75),
        [SeatPositions.LeftCircleBottom]: circleLeft(Math.PI * 0.5),
        [SeatPositions.MiddleBottom]: circleMiddle(Math.PI * 0.5),
        [SeatPositions.RightCircleTop]: circleRight(Math.PI * 1.5),
        [SeatPositions.RightCircleTopRight]: circleRight(Math.PI * 1.75),
        [SeatPositions.RightCircleRight]: circleRight(Math.PI * 0),
        [SeatPositions.RightCircleBottomRight]: circleRight(Math.PI * 0.25),
        [SeatPositions.RightCircleBottom]: circleRight(Math.PI * 0.5),
    };
};
