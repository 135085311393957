import { motion } from 'framer-motion';
import { Coordinates } from 'tableCoordinateResolvers/types';
import { FC } from 'react';
import styled from 'styled-components';

const Main = styled(motion.div)<{ zIndex: number }>`
    position: absolute;
    z-index: ${(p) => p.zIndex || 6};
    top: 0;
    left: 0;
`;

// This makes absolutely positioned element be centered around the point of the position
// which is what we want
const Center = styled.div`
    transform: translate3d(-50%, -50%, 0);
`;

export type PositioningContainerProps = {
    position: Coordinates;
    animate?: Coordinates;
    className?: string;
};

const PositioningContainer: FC<PositioningContainerProps> = ({
    children,
    position,
    animate,
    className = '',
}) => {
    return position ? (
        <Main
            initial={{ x: position.x, y: position.y }}
            animate={animate || { x: position.x, y: position.y }}
            zIndex={position.zIndex}
        >
            <Center className={className}>{children}</Center>
        </Main>
    ) : null;
};

export default PositioningContainer;
