import { createContext, useContext } from 'react';
import type { GameObject } from '@magicyard/poker-game/src/Game';
import { GamePhase, getPlayer } from '@magicyard/poker-game/src/Game';
export type GameContextType = GameObject;

const GameContext = createContext<GameContextType>(null);

export const useGameContext = () => useContext(GameContext);

export default GameContext;
export { GamePhase, getPlayer };
