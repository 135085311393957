import styled from 'styled-components';

const Lights = styled.div`
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/images/decor/lights.png') no-repeat center / cover;
    opacity: 0.6;
`;

export default Lights;
