import { Coordinates } from '../tableCoordinateResolvers/types';

export const createStyle = (dims: Coordinates) => {
    return {
        left: dims.x,
        top: dims.y,
        x: dims.x,
        y: dims.y,
    };
};
