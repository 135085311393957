import type { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import type { Variants } from 'framer-motion';

export const BaseLabel = styled(motion.div)`
    padding: 3px 12px;
    border-radius: 40px;
    box-sizing: content-box;
    color: white;
    font-size: 1.7rem;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
    text-transform: capitalize;
`;

const LabelContainer = styled.div`
    position: absolute;
    left: 80%;
    top: 20%;
    z-index: 2;
`;

export enum LabelContainerTypes {
    Call = 'call',
    Raise = 'raise',
    Check = 'check',
    AllIn = 'all in',
    Fold = 'fold',
    Bet = 'bet',
}

const variants: Variants = {
    [LabelContainerTypes.Call]: {
        backgroundColor: '#134000',
        borderColor: '#00e700',
    },
    [LabelContainerTypes.Check]: {
        backgroundColor: '#134000',
        borderColor: '#00e700',
    },
    [LabelContainerTypes.Raise]: {
        backgroundColor: '#3c2a00',
        borderColor: '#ffcc00',
    },
    [LabelContainerTypes.Bet]: {
        backgroundColor: '#3c2a00',
        borderColor: '#ffcc00',
    },
    [LabelContainerTypes.AllIn]: {
        backgroundColor: '#013950',
        borderColor: '#00b7ff',
    },
    [LabelContainerTypes.Fold]: {
        backgroundColor: '#460002',
        borderColor: '#e70000',
    },
};

export type LabelContainerProps = {
    type: LabelContainerTypes;
};

export const Label: FC<LabelContainerProps> = ({ type }) => (
    <LabelContainer>
        <BaseLabel variants={variants} animate={type}>
            {type}
        </BaseLabel>
    </LabelContainer>
);
