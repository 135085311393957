import { EffectsPluginConfig } from 'bgio-effects/dist/types';

export enum Effects {
    Call = 'call',
    Check = 'check',
    Raise = 'raise',
    AllIn = 'allIn',
    Fold = 'fold',
    PlayerChipsAction = 'playerChipsAction',
    ChipsToMainPot = 'chipsToMainPot',
    MainPotToPlayer = 'mainpotToPlayer',
    DealCards = 'dealCards',
    FlipCard = 'flipCard',
}

export const config: EffectsPluginConfig = {
    // Declare the effect types you need.
    effects: {
        [Effects.Call]: {
            duration: 0.5,
        },
        [Effects.Check]: {
            duration: 0.5,
        },
        [Effects.Raise]: {
            duration: 0.5,
        },
        [Effects.AllIn]: {
            duration: 0.5,
        },
        [Effects.Fold]: {
            duration: 0.5,
        },
        [Effects.DealCards]: {
            duration: 0.5,
        },
        [Effects.FlipCard]: {
            duration: 1,
            create: (card) => ({ card }),
        },
        [Effects.PlayerChipsAction]: {
            create: (playerID) => ({ playerID }),
        },
        [Effects.ChipsToMainPot]: {
            duration: 1,
        },
        [Effects.MainPotToPlayer]: {
            create: (playerID) => ({ playerID }),
        },
    },
};
