import MainPotComponent from 'components/MainPotComponent';
import { useGameContext } from 'context/GameContext';
import type { FC } from 'react';
import styled from 'styled-components';

export const MainPotContainer = styled.div`
    grid-area: main-pot;
    justify-self: center;
    align-self: end;
`;

const MainPot: FC = () => {
    const {
        G: {
            table: { pot },
        },
    } = useGameContext();

    return (
        <MainPotContainer>
            <MainPotComponent amount={pot} />
        </MainPotContainer>
    );
};

export default MainPot;
