import { GamePhase, IPlayer, LastMove } from '@magicyard/poker-game/src/Game';
import {
    PlayState,
    DealerState,
    AnnouncementState,
} from 'components/Avatar/Avatar';

export const extractDealerState = (
    playerAtSeat: number[],
    player: IPlayer,
    bigBlind: number,
    smallBlind: number,
    dealer: number
): DealerState => {
    return player.id === playerAtSeat[bigBlind]
        ? DealerState.BigBlind
        : player.id === playerAtSeat[smallBlind]
        ? DealerState.SmallBlind
        : player.id === playerAtSeat[dealer]
        ? DealerState.Dealer
        : DealerState.None;
};

export const extractPlayState = (
    player: IPlayer,
    currentPlayer: number
): PlayState => {
    const isPlaying = player.id === currentPlayer;

    // order matters!
    return player.folded
        ? PlayState.Fold
        : !player.active
        ? PlayState.InActive
        : isPlaying
        ? PlayState.Playing
        : player.lastMove === LastMove.bet
        ? PlayState.Bet
        : player.lastMove === LastMove.raise
        ? PlayState.Raise
        : player.lastMove === LastMove.check
        ? PlayState.Check
        : player.allIn
        ? PlayState.AllIn
        : player.lastMove === LastMove.call
        ? PlayState.Call
        : PlayState.WaitingForFirstMove;
};

export const extractAnnouncementState = (
    player: IPlayer,
    gamePhase: GamePhase,
    winnerIDs: number[]
): AnnouncementState => {
    if (gamePhase === GamePhase.AnnounceWinners) {
        const didWin = winnerIDs.includes(player.id);
        return didWin ? AnnouncementState.Winner : AnnouncementState.Loser;
    }
    return null;
};
