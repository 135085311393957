import styled from 'styled-components';

interface OverlayProps {
    active: boolean;
}

const Overlay = styled.div<OverlayProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.3);
    z-index: 500;
    transition: opacity ease-out 1s;
    opacity: ${(p) => (p.active ? '1' : '0')};
    pointer-events: none;
`;

export type { OverlayProps };
export default Overlay;
