import React, { createContext, useContext, useEffect } from "react";
import mixpanel, { Mixpanel } from "mixpanel-browser";

const context = createContext<Mixpanel | null>(null);

export const MixpanelProvider = context.Provider;

export const MixpanelConsumer = context.Consumer;

export function useMixpanel(): Mixpanel | null {
  return useContext(context);
}

export const InitializedMixpanel = (props: { children: React.ReactNode }) => {
  useEffect(() => {
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.init("35de10e0aff328c9fb3a61d885007d76", {
      debug: true,
      api_host: "https://mp.magicpoker.app",
    });

    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.track("Mixpanel Loaded [Platform Client]");
  }, []);

  return <MixpanelProvider value={mixpanel}>{props.children}</MixpanelProvider>;
};
