import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

export interface CardProps {
    fileName: string;
    onClick?: () => unknown;
    isTransparent?: boolean;
    preventLongPress?: boolean;
    shadow?: boolean;
}

const StyledCard = styled.img<{
    shadow: boolean;
    transparent: boolean;
}>`
    user-select: none;
    filter: ${(p) =>
        (p.shadow ? 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.6)) ' : '') +
        (p.transparent ? 'contrast(0.5) brightness(0.7)' : '')};
`;

const Card: FC<CardProps> = ({
    fileName,
    onClick,
    isTransparent = false,
    preventLongPress = false,
    shadow = false,
    ...props
}) => {
    return (
        <StyledCard
            transparent={isTransparent}
            shadow={shadow}
            onClick={onClick}
            src={`${process.env.PUBLIC_URL}/images/cards/${fileName}.png`}
            {...props}
        />
    );
};

export default styled(Card)``;
