import { GameObject } from '@magicyard/poker-game/src/Game';
import GameContext from 'context/GameContext';
import { InitializedMixpanel } from 'context/MixpanelContext';
import type { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'theme/theme';

const Providers: FC<GameObject> = ({ children, ...props }) => {
    return (
        <InitializedMixpanel>
            <GameContext.Provider value={props}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </GameContext.Provider>
        </InitializedMixpanel>
    );
};

export default Providers;
