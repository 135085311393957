export enum SeatPositions {
    LeftCircleTop,
    LeftCircleTopLeft,
    LeftCircleLeft,
    LeftCircleBottomLeft,
    LeftCircleBottom,
    MiddleBottom,
    RightCircleTop,
    RightCircleTopRight,
    RightCircleRight,
    RightCircleBottomRight,
    RightCircleBottom,
}
