import { Effects } from '@magicyard/poker-game/src/effectsConfig';
import { useEffectListener } from 'bgio-effects/dist/react';
import { useImgRectContext } from 'context/RectContext';
import { useMemo, useState } from 'react';
import { Coordinates } from './types';
import { createSeats } from './createSeats';
import { SeatPositions } from './SeatPositions';
import useTableMainPot from './useTableMainPot';

// pretty much the same as useTableSeats but with shorter radius

export type UseTableBetsRet = {
    loading: boolean;
    getBetCoord: (location: SeatPositions) => Coordinates;
    SeatPositions: typeof SeatPositions;
};

const useTableBets = (): UseTableBetsRet => {
    const rect = useImgRectContext();
    const mainPotCoords = useTableMainPot();
    const betMap = useMemo(() => {
        if (rect) {
            return createSeats(rect, rect.height * 0.25, rect.height * 0.25);
        }
    }, [rect]);

    const [isChipsToMainPot, setIsChipsToMainPot] = useState(false);

    useEffectListener(
        Effects.ChipsToMainPot,
        () => {
            setIsChipsToMainPot(true);
        },
        []
    );

    useEffectListener(
        'effects:end',
        () => {
            setIsChipsToMainPot(false);
        },
        []
    );

    return {
        loading: !rect || !betMap,
        getBetCoord: (location: SeatPositions) =>
            isChipsToMainPot ? mainPotCoords : betMap[location],
        SeatPositions,
    };
};

export default useTableBets;
