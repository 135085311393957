import { NewStack } from '@magicyard/poker-components';
import { FC } from 'react';

export type MainPotProps = {
    amount: number;
    className?: string;
};

const MainPotComponent: FC<MainPotProps> = ({ amount, className }) => {
    return (
        <NewStack
            chipURL="/images/mainpot-chips.png"
            amount={amount}
            className={className}
        />
    );
};

export default MainPotComponent;
