import { useImgRectContext } from 'context/RectContext';
import { useMemo } from 'react';
import { Coordinates } from './types';
import { createSeats } from './createSeats';
import { SeatPositions } from './SeatPositions';
import { useGameContext } from 'context/GameContext';
import { Z_POSITIONS } from 'utils/consts';
import { GamePhase } from '@magicyard/poker-game/src/Game';

export type UseTableSeatsRet = {
    loading: boolean;
    getSeatCoord: (
        location: SeatPositions,
        isWinner?: boolean,
        isTurn?: boolean
    ) => Coordinates;
    SeatPositions: typeof SeatPositions;
};

const useTableSeats = (): UseTableSeatsRet => {
    const {
        ctx: { phase },
    } = useGameContext();
    const rect = useImgRectContext();
    const seatMap = useMemo(() => {
        if (rect) {
            return createSeats(rect, rect.height * 0.6, rect.height * 0.6);
        }
    }, [rect]);

    const winSeatMap = useMemo(() => {
        if (rect) {
            return createSeats(rect, rect.height * 0.5, rect.height * 0.5);
        }
    }, [rect]);

    const turnSeatMap = useMemo(() => {
        if (rect) {
            return createSeats(rect, rect.height * 0.5, rect.height * 0.5);
        }
    }, [rect]);

    return {
        loading: !rect || !seatMap,
        getSeatCoord: (location, isWinner = false, isTurn = false) =>
            isWinner
                ? {
                      ...winSeatMap[location],
                      zIndex: Z_POSITIONS['WIN_PHASE_WINNER'],
                  }
                : isTurn
                ? {
                      ...turnSeatMap[location],
                      zIndex: Z_POSITIONS['HAND_PHASE_PLAYER'],
                  }
                : {
                      ...seatMap[location],
                      zIndex:
                          phase === GamePhase.AnnounceWinners
                              ? Z_POSITIONS['WIN_PHASE_LOSER']
                              : Z_POSITIONS['HAND_PHASE_PLAYER'],
                  },
        SeatPositions,
    };
};

export default useTableSeats;
