import React, { FC } from 'react';

// Style
import styled from 'styled-components/macro';

interface InfoProps {
    bold?: boolean;
    size?: 'xs' | 's' | 'm' | 'l';
}

export const StyledInfo = styled.p<InfoProps>`
    display: flex;
    align-items: center;
    margin: 0;
    font-size: ${props => props.theme.font.size[props.size]};
    color: ${props => props.theme.palette.white};
    font-weight: ${
        props => props.bold ? props.theme.font.weight.bold : props.theme.font.weight.regular
    };
    letter-spacing: ${props => props.theme.font.letterSpacing[props.size] || props.theme.font.letterSpacing.default};
    text-shadow: 1px 1px 12px rgb(0 0 0 / 51%), 1px 1px 2px rgb(0 0 0 / 40%);

    svg {
        position: relative;
        bottom: 2px;
        width: ${props => props.theme.font.size.l};
        height: ${props => props.theme.font.size.l};
        margin-right: .3em;
    }
`;

const Info: FC<InfoProps> = ({ bold, size, children, ...props }) => {
    return <StyledInfo bold={bold} size={size || 's'} {...props}>
        {children}
    </StyledInfo>
}

export default Info;
