import React, { useMemo } from 'react';
import styled from 'styled-components';

interface ChipProps {
    value: number;
}

const ChipStack = () => {
    return (
        <img src={'/images/player-chips.png'} alt="" width="36" height="auto" />
    );
};

export const ChipContainer = styled.div<{ offsetPx: number }>`
    width: 40px;
    height: 4px;
    overflow: visible;
    margin-left: ${(props) => props.offsetPx}px;
    filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.1));

    img {
        width: 100%;
        max-width: 20px;
    }
`;

const ContainedChip = ({ value }: ChipProps) => {
    const offsetPx = useMemo(() => Math.round(Math.random() * 3), []);

    return (
        <ChipContainer offsetPx={offsetPx}>
            <ChipStack />
        </ChipContainer>
    );
};

export default ContainedChip;
