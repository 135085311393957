import { IPlayer } from '@magicyard/poker-game/src/Game';
import { useGameContext } from 'context/GameContext';
import useSeatMapper from 'tableCoordinateResolvers/useSeatMapper';
import useTableSeats from 'tableCoordinateResolvers/useTableSeats';
import { FC } from 'react';
import Player from 'components/Player/Player';
import PositioningContainer from './components/PositioningContainer';

export type PlayerRendererProps = {
    player: IPlayer;
};

const PlayerRenderer: FC<PlayerRendererProps> = ({ player }) => {
    const {
        G: { winnerIDs: allWinnerIDs },
        ctx: { currentPlayer },
    } = useGameContext();

    const { loading: seatsLoading, getSeatCoord } = useTableSeats();

    const { getSeatPositionForPlayerID } = useSeatMapper();

    if (seatsLoading) {
        return;
    }

    const isWinner = allWinnerIDs.includes(player.id);

    const seatPosition = getSeatPositionForPlayerID(player.id);

    const coords = getSeatCoord(
        seatPosition,
        isWinner,
        +currentPlayer === +player.id
    );

    return (
        <PositioningContainer position={coords}>
            <Player player={player} />
        </PositioningContainer>
    );
};

export default PlayerRenderer;
