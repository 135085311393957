import styled from 'styled-components';

export const NameLabel = styled.div`
    position: absolute;
    top: -23%;
    z-index: 2;
    color: ${(p) => p.theme.palette.white};
    font-size: 1.5rem;
    border:rgba(255, 255, 255, 0.95);
    border-style: solid;
    border-width:1px;
    border-radius: 4rem;
    box-shadow:rgb(255 255 255) 0px 0px 0px 0px;
    text-align: center;
    padding: 0.5rem 1.5rem;
    background: black;
    min-width: 50%;
    box-sizing: content-box;
    max-width: 150%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
