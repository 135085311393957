import React from 'react';
import type { FC } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Card } from '..';
import { CardProps } from '../Card';

export type FlippingCardProps = CardProps & {
    backFileName?: string;
    fileName: string;
    isFlipped?: boolean;
};

const FlippingCard: FC<FlippingCardProps> = ({
    backFileName = 'RED_BACK',
    onClick,
    fileName,
    isFlipped,
    ...cardProps
}) => {
    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            {/* Front */}
            <Card {...cardProps} fileName={fileName}></Card>
            {/* Back */}
            <Card {...cardProps} fileName={backFileName}></Card>
        </ReactCardFlip>
    );
};

export default FlippingCard;
