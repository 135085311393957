import React from 'react';
import styled from 'styled-components';

interface StackProps {
    type?: 'bet' | 'stack';
    value: number;
    area?: number;
}

interface StyledStackType {
    area?: number;
    offsetFirstRow?: boolean;
}

const StyledStackBase = styled.div<StyledStackType>`
    z-index: 1;
    bottom: -23%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledBet = styled(StyledStackBase)`
    width: 6.1vw;
    box-sizing: content-box;
`;

const StyledPlayerStack = styled(StyledStackBase)<StyledStackType>`
    position: absolute;
`;

const Container = styled.div<{ type: string }>`
    position: relative;
    border: ${(p) => (p.type === 'bet' ? '1px solid #499b72' : 'none')};
    box-shadow: ${(p) =>
        p.type === 'bet' ? '0 0 5px 0 #09170f inset' : 'none'};
    padding: 0.4rem 2rem 0.4rem 3rem;
    background-color: ${(p) =>
        p.type === 'bet' ? 'rgba(12,42,34,0.7)' : '#1a1a1a'};
    color: white;
    font-size: 15px;
    max-height: 30px;
    font-size: 15px;
    border:rgba(255, 255, 255, 0.95);
    border-style: solid;
    border-width:1px;
    border-radius: 40px;
    text-align: left;
    z-index: 1;
    display: flex;
    align-items: center;
`;

const Chip = styled.img`
    position: absolute;
    z-index: 1;
    left: -0.5rem;
    width: 2rem;
    height: auto;
    top: -2px;
`;

const StyledBetLabel = styled.div`
    color: ${(p) => p.theme.palette.white};
    width: 100%;
`;

const Info = styled.div`
    font-size: 2rem;
    width: 100%;
    margin-left: -0.6rem;
    text-align: center;
`;

const Stack = ({ value, area, type, ...props }: StackProps) => {
    const StackComp = type === 'bet' ? StyledBet : StyledPlayerStack;
    return (
        <StackComp area={area} {...props}>
            <Container type={type}>
                <Chip src="/images/player-chips.png" />
                <StyledBetLabel>
                    <Info>${value}</Info>
                </StyledBetLabel>
            </Container>
        </StackComp>
    );
};

export default React.memo(Stack);
