export const palette = {
    black: '#333',
    white: '#fff',
    success: '#4caf50',
    warning: '#ff9800',
    error: '#f44336',
    info: '#2196f3',
    table: '#4aad4a',
    tableBorder: '#a95555',
    cardBorder: '#b30000',
    active: 'rgba(255, 255, 255, 0.4)',
    dealer: 'crimson',
};

export const font = {
    family: '',
    size: {
        l: '2.5rem',
        m: '2rem',
        s: '1.9rem',
        xs: '1.5rem',
    },
    weight: {
        extraBold: 900,
        bold: 700,
        regular: 400,
    },
    letterSpacing: {
        default: '0.035em',
        l: '0.05em',
    },
};

export const sizes = {
    avatar: {
        height: 80,
        width: 80,
    },
};

const layoutBySeatArea = {
    // Top seats
    seat5: 'row',
    seat6: 'row',
    // Right seats
    seat7: 'column',
    seat8: 'column',
    // Bottom seats
    seat2: 'row',
    seat1: 'row',
    seat0: 'row',
    // Left seats
    seat4: 'column',
    seat3: 'column',
};

const getOffsetForStack = {
    seat4: 'transform: rotate(60deg);',
    seat3: 'transform: rotate(-60deg);',
    seat7: 'transform: rotate(-240deg);',
    seat8: 'transform: rotate(240deg);',
};

const theme = {
    palette,
    font,
    sizes,
    getOffsetForStack,
    layoutBySeatArea,
};

export default theme;
