import _ from 'lodash';
import { ICard, Card } from './Cards';
import { IHand, calculateHand, compareHands } from './Hand';
import { k_combinations } from './utils';

import { IPlayer } from './Game';

export const Deck = (): ICard[] => {
    let cards: ICard[] = [];
    for (let i: number = 2; i <= 14; i++) {
        for (let j: number = 0; j <= 3; j++) {
            let card: ICard = Card(i, j);
            cards.push(card);
        }
    }
    cards = _.shuffle(cards);
    return cards;
};

export function calculateBestHand(
    playerHand: ICard[],
    communityCards: ICard[]
): IHand {
    let cardCombos: ICard[][] = k_combinations(
        playerHand.concat(communityCards),
        5
    );
    let hands: IHand[] = _.map(cardCombos, (cards) => {
        return calculateHand(cards);
    });
    hands.sort(compareHands);
    return hands[hands.length - 1];
}

export function bestHandCardsLocation(
    winner: IPlayer,
    communityCards: ICard[],
    everyoneFolded: boolean
) {
    const defaultRet = {
        communityWinningCardsLocation: [0, 1, 2, 3, 4],
        handWinningCardsLocation: [],
    };
    if (everyoneFolded) {
        return defaultRet;
    }

    let communityWinningCardsLocation = [];
    let handWinningCardsLocation = [];

    let winnerBestHand = calculateBestHand(winner.cards, communityCards);
    if (!winnerBestHand) {
        return defaultRet;
    }
    communityCards.forEach((card, i) => {
        if (winnerBestHand.cards.includes(card)) {
            communityWinningCardsLocation.push(i);
        }
    });

    winner.cards.forEach((card, i) => {
        if (winnerBestHand.cards.includes(card)) {
            handWinningCardsLocation.push(i);
        }
    });

    return { communityWinningCardsLocation, handWinningCardsLocation };
}

export function getWinningPlayers(rankedPlayers) {
    let winners = [rankedPlayers[0]];
    //As long as the runner-up matches the best hand, they will split the pot.
    for (let i = 0; i < rankedPlayers.length - 1; i++) {
        if (
            compareHands(
                rankedPlayers[i].bestHand,
                rankedPlayers[i + 1].bestHand
            ) === 0
        ) {
            winners.push(rankedPlayers[i + 1]);
        } else {
            break;
        }
    }
    return winners;
}
