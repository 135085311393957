import { FC, Fragment } from 'react';
import { Coordinates } from '../tableCoordinateResolvers/types';
import styled from 'styled-components';
import { Stack } from '@magicyard/poker-components';
import useTableSeats from 'tableCoordinateResolvers/useTableSeats';
import useTableBets from 'tableCoordinateResolvers/useTableBets';
import useSeatMapper from 'tableCoordinateResolvers/useSeatMapper';
import PlayerRenderer from 'Renderers/PlayerRenderer';
import PositioningContainer from './components/PositioningContainer';
import { useLatestPropsOnEffect } from 'bgio-effects/dist/react';
import { Effects } from '@magicyard/poker-game/src/effectsConfig';

const StackContainer = styled.div<{ isVisible: boolean }>`
    visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
`;

const BetRenderer: FC<{ coords: Coordinates }> = ({ children, coords }) => {
    return (
        <PositioningContainer position={coords}>
            {children}
        </PositioningContainer>
    );
};

const SeatsManager = () => {
    const { loading: seatsLoading } = useTableSeats();
    const { getBetCoord, loading: betsLoading } = useTableBets();
    const {
        G: { players },
    } = useLatestPropsOnEffect(Effects.FlipCard);

    const { getSeatPositionForPlayerID } = useSeatMapper();

    if (seatsLoading || betsLoading) {
        return null;
    }

    return (
        <>
            {players.map((player) => {
                const seatPosition = getSeatPositionForPlayerID(player.id);
                return (
                    <Fragment key={player.id}>
                        <PlayerRenderer player={player} />
                        <BetRenderer coords={getBetCoord(seatPosition)}>
                            <StackContainer isVisible={!!player.currentBet}>
                                <Stack
                                    type="bet"
                                    value={player.currentBet}
                                    area={1}
                                />
                            </StackContainer>
                        </BetRenderer>
                    </Fragment>
                );
            })}
        </>
    );
};

export default SeatsManager;
