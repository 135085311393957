import _ from 'lodash';
import { ICard, Card } from './Cards';

export interface IHand {
    name: string;
    cards: ICard[];
    kickers: ICard[];
    value: number;
}

export const calculateHand = (cards: ICard[]): IHand => {
    let name: string;
    let value: number;

    cards.sort((a, b) => {
        return a.value - b.value;
    });

    let [modeMapping, kickers] = getModeMapping(cards);

    if (isStraightFlush(cards)) {
        kickers = [];
        name = 'straight flush';
        value = 1000;
    } else if (isFours(modeMapping)) {
        name = 'four of a kind';
        value = 900;
    } else if (isFullHouse(modeMapping)) {
        kickers = [];
        name = 'full house';
        value = 800;
    } else if (isFlush(cards)) {
        kickers = [];
        name = 'flush';
        value = 700;
    } else if (isStraight(cards)) {
        kickers = [];
        name = 'straight';
        value = 600;
    } else if (isBabyStraight(cards)) {
        kickers = [];
        name = 'straight';
        value = 500;
    } else if (isThrees(modeMapping)) {
        name = 'three of a kind';
        value = 400;
    } else if (isTwoPair(modeMapping)) {
        name = 'two pair';
        value = 300;
    } else if (isPair(modeMapping)) {
        name = 'pair';
        value = 200;
    } else {
        kickers = cards.slice(0);
        name = 'high card';
        value = 100;
    }

    return {
        name,
        cards,
        kickers,
        value,
    };
};

const isStraightFlush = (cards: ICard[]): boolean => {
    return isStraight(cards) && isFlush(cards);
};

const isFours = (modeMapping): boolean => {
    return modeMapping[4] === 1;
};

const isFullHouse = (modeMapping): boolean => {
    return modeMapping[2] === 1 && modeMapping[3] === 1;
};

const isFlush = (cards: ICard[]): boolean => {
    return (
        cards[0].suit == cards[1].suit &&
        cards[1].suit == cards[2].suit &&
        cards[2].suit == cards[3].suit &&
        cards[3].suit == cards[4].suit
    );
};

const isStraight = (cards: ICard[]): boolean => {
    return (
        cards[0].value == cards[1].value - 1 &&
        cards[1].value == cards[2].value - 1 &&
        cards[2].value == cards[3].value - 1 &&
        cards[3].value == cards[4].value - 1
    );
};

const isBabyStraight = (cards: ICard[]): boolean => {
    return (
        cards[0].value == cards[1].value - 1 &&
        cards[1].value == cards[2].value - 1 &&
        cards[2].value == cards[3].value - 1 &&
        cards[4].value == 14 &&
        cards[0].value == 2
    );
};

const isThrees = (modeMapping): boolean => {
    return modeMapping[3] === 1;
};

const isTwoPair = (modeMapping): boolean => {
    return modeMapping[2] === 2;
};

const isPair = (modeMapping): boolean => {
    return modeMapping[2] === 1;
};

//Example: returns
// { 2: 1, 3: 1, 4: 0 } (FULL HOUSE)
// { 2: 2, 3: 0, 4: 0 } (TWO PAIR)
const getModeMapping = (cards: ICard[]): [any, ICard[]] => {
    let consecutives = [1, 1];
    // let consecutives2 = 1;
    let whichConsecutive = 0;
    let kickers = cards.slice(0);
    let indicesToRemove: any = {};
    for (let i = 0; i < cards.length - 1; i++) {
        if (cards[i].value == cards[i + 1].value) {
            // Remove from kickers
            _.remove(kickers, (val) => {
                return val === cards[i] || val === cards[i + 1];
            });
            consecutives[whichConsecutive]++;

            // If we've already found consecutives but now came across different value card, start a new counter
        } else if (consecutives[whichConsecutive] > 1) {
            whichConsecutive++;
        }
    }
    _.each(indicesToRemove, (value, key) => {
        _.remove(kickers, (val) => {
            return val === value;
        });
    });
    let modeMapping: any = {
        2: 0, // pairs
        3: 0, // threes
        4: 0, // fours
    };
    modeMapping[consecutives[0]] = 1;
    modeMapping[consecutives[1]] = 1;
    if (consecutives[0] == 2 && consecutives[1] == 2) {
        modeMapping[consecutives[0]] = 2;
    }
    return [modeMapping, kickers];
};

const getHighestCardValue = (cards: ICard[]): number => {
    return _.maxBy(cards, (card) => {
        return card.value;
    }).value;
};

//Compare two hands, seeing which one is higher. Returns -1, 0, 1 scheme used by JS sort callbacks
export const compareHands = (hand1: IHand, hand2: IHand): number => {
    if (hand1.value > hand2.value) {
        return 1;
    } else if (hand1.value == hand2.value) {
        //First compare hand cards (non-kickers)
        let myHandCards: ICard[] = _.difference(hand1.cards, hand1.kickers);
        let otherHandCards: ICard[] = _.difference(hand2.cards, hand2.kickers);
        let comparison = compareEqualHands(myHandCards, otherHandCards);
        if (comparison == 0) {
            return compareEqualHands(hand1.kickers, hand2.kickers);
        }
        return comparison;
    } else {
        return -1;
    }
};

//Compare two sets of cards.
const compareEqualHands = (set1: ICard[], set2: ICard[]): number => {
    //Make sure they're sorted asc first
    set1.sort((a, b) => {
        return b.value - a.value;
    });
    set2.sort((a, b) => {
        return b.value - a.value;
    });
    for (let i = 0; i < set1.length && i < set2.length; i++) {
        if (set1[i].value > set2[i].value) return 1;
        else if (set1[i].value < set2[i].value) return -1;
    }
    return 0;
};

/*
//Some brief TDD asserts to aid in dev
let hand:Hand = new Hand([
  new Card(CardValue.Two, CardSuit.Clubs),
  new Card(CardValue.Three, CardSuit.Clubs),
  new Card(CardValue.Four, CardSuit.Clubs),
  new Card(CardValue.Five, CardSuit.Clubs),
  new Card(CardValue.Six, CardSuit.Clubs)])
console.debug(hand.name);
*/
