import { Effects } from '@magicyard/poker-game/src/effectsConfig';
import usePlayAudioOnEffect from './usePlayAudioEffect';
import usePlayAudioOnWinnerSequence from './usePlayAudioOnWinnerSeqeuence';

const useAudio = () => {
    usePlayAudioOnEffect(Effects.Raise, '/sounds/chips.mpeg');
    usePlayAudioOnEffect(Effects.Call, '/sounds/chips.mpeg');
    usePlayAudioOnEffect(Effects.Check, '/sounds/check.mp3');
    usePlayAudioOnEffect(Effects.AllIn, '/sounds/allin.mp3');
    usePlayAudioOnEffect(Effects.Fold, '/sounds/fold.mp3');
    usePlayAudioOnEffect(Effects.DealCards, '/sounds/dealcards.mp3');
    usePlayAudioOnEffect(Effects.FlipCard, '/sounds/flipcard.mp3');

    usePlayAudioOnWinnerSequence();
};

export default useAudio;
