import { useCallback, useEffect, useState } from 'react';
import useWinners from './useWinners';

const useSequenceWinners = (durationMS: number = 5000) => {
    const winnerIDsByHeirarchy = useWinners();
    const [sequencedWinnerIDs, setSequencedWinnerIDs] = useState<number[]>([]);

    const safeSetSequencedWinnerIDs = useCallback(
        (winnerIDs: number[]) => {
            if (!!winnerIDsByHeirarchy.length) {
                setSequencedWinnerIDs(winnerIDs);
            }
        },
        [winnerIDsByHeirarchy]
    );

    useEffect(() => {
        if (!winnerIDsByHeirarchy.length) {
            setSequencedWinnerIDs([]);
        } else {
            winnerIDsByHeirarchy.forEach((winnerIDs, index) => {
                setTimeout(
                    () => safeSetSequencedWinnerIDs(winnerIDs),
                    durationMS * index
                );
            });

            if (winnerIDsByHeirarchy.length > 1) {
                setTimeout(
                    () => setSequencedWinnerIDs([]),
                    durationMS * winnerIDsByHeirarchy.length
                );
            }
        }
    }, [durationMS, winnerIDsByHeirarchy, safeSetSequencedWinnerIDs]);
    return sequencedWinnerIDs;
};

export default useSequenceWinners;
