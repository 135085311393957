import { useGameContext } from 'context/GameContext';
import invert from 'lodash/invert';
import { useMemo } from 'react';
import { SeatPositions } from './SeatPositions';

const useSeatMapper = () => {
    const {
        G: { playerAtSeat, dealer, players },
    } = useGameContext();

    const seatForPlayer = useMemo(() => invert(playerAtSeat), [playerAtSeat]);

    /*  each array index is mapped to seatForPlayer
        example:
        seatForPlayer = { 0: 1, 5: 4 }
        playerId = 0
        so seatForPlayer[playerId] is the seatMap index which points to the player's seat position
    */
    const seatMap = useMemo(
        () => [
            SeatPositions.RightCircleTop,
            SeatPositions.RightCircleTopRight,
            SeatPositions.RightCircleRight,
            SeatPositions.RightCircleBottomRight,
            SeatPositions.RightCircleBottom,
            SeatPositions.MiddleBottom,
            SeatPositions.LeftCircleBottom,
            SeatPositions.LeftCircleBottomLeft,
            SeatPositions.LeftCircleLeft,
            SeatPositions.LeftCircleTopLeft,
            SeatPositions.LeftCircleTop,
        ],
        []
    );

    const seatToSeatMapIndex = useMemo(() => {
        const distanceBetweenPlayers = seatMap.length / players.length;

        // Some seats in playerAtSeat may be `null` (accordingly, seatForPlayer has a `null` key, and the values may not be consecutive)
        // We need to be able to map *filled* seats evenly to the table
        // 1. Map to entries that include the seat (== index), we need to save it.
        // 2. filter out entries with a null player
        // 3. Give each seat a corresponding seatMapIndex using `index` in the entries array, which is now consecutive.
        return Object.fromEntries(
            playerAtSeat
                .map((player, seat) => {
                    return [seat, player];
                })
                .filter(([_seat, player]) => player !== null)
                .map(([seat, _player], index) => {
                    const seatMapIndex = Math.floor(index * distanceBetweenPlayers);
                    return [seat, seatMapIndex];
                })
        );
    }, [players.length, seatMap.length, playerAtSeat]);

    const getSeatPositionForPlayerID = (playerID: number): SeatPositions => {
        const playerSeat = seatToSeatMapIndex[seatForPlayer[playerID]];

        return seatMap[playerSeat];
    };

    const dealerPlayerID = playerAtSeat[dealer];

    return {
        seatForPlayer,
        getSeatPositionForPlayerID,
        dealerPlayerID,
    };
};

export default useSeatMapper;
