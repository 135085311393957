import { GamePhase } from '@magicyard/poker-game/src/Game';
import { useGameContext } from 'context/GameContext';
import { useImgRectContext } from 'context/RectContext';
import { useMemo } from 'react';
import { Z_POSITIONS } from 'utils/consts';
import { Coordinates } from './types';

export type TableCards = Coordinates & {
    cardsContainerHeight: number;
    cardsContainerWidth: number;
};

const useTableCards = (): TableCards => {
    const rect = useImgRectContext();

    const {
        ctx: { phase },
    } = useGameContext();

    const coords = useMemo(() => {
        if (rect) {
            return {
                x: rect.x + rect.width * 0.5,
                y: rect.y + rect.height * 0.5,
                cardsContainerHeight: rect.height * 0.27,
                cardsContainerWidth: rect.width * 0.415,
                zIndex:
                    phase === GamePhase.AnnounceWinners
                        ? Z_POSITIONS['WIN_PHASE_CARDS']
                        : Z_POSITIONS['HAND_PHASE_CARDS'],
            };
        }
    }, [rect, phase]);

    return coords;
};

export default useTableCards;
