import styled from 'styled-components';

const Img = styled.img`
    position: absolute;
    z-index: 0;
    top: 5px;
    right: 0;
    height: 50vh;
`;

const Windows = () => {
    return <Img src="/images/decor/windows.png" />;
};

export default Windows;
