import React from 'react';
import styled from 'styled-components';

export const TableImg = styled.img<{ zIndex: number }>`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 67vw;
    height: auto;
    z-index: ${(p) => p.zIndex};
`;

export const TableShadowImg = styled.img<{ zIndex: number }>`
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 67vw;
    height: auto;
    z-index: ${(p) => p.zIndex - 1};
`;

export type TableProps = {
    className?: string;
    zIndex?: number;
};

const Table = React.forwardRef<HTMLImageElement, TableProps>(
    ({ className = '', zIndex = 2 }, ref: React.Ref<HTMLImageElement>) => {
        return (
            <div className={className}>
                <TableImg
                    ref={ref}
                    src="/images/table.png"
                    alt=""
                    zIndex={zIndex}
                />
                <TableShadowImg
                    src="/images/shadow.png"
                    alt=""
                    zIndex={zIndex}
                />
            </div>
        );
    }
);

export default Table;
