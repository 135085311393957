import Books from 'components/Decor/Books';
import Lights from 'components/Decor/Lights';
import Plant from 'components/Decor/Plant';
import Windows from 'components/Decor/Windows';
import { useGameContext } from 'context/GameContext';
import { useMixpanel } from 'context/MixpanelContext';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

const ScreenLayout = styled.div`
    width: 100vw;
    height: 100vh;
    background: url('/images/background.jpg') no-repeat center / cover;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AppContainer: FC = ({ children }) => {
    const mixpanel = useMixpanel();
    const {
        G: { mgy_match_id },
    } = useGameContext();

    useEffect(() => {
        mixpanel.track('Poker Display Loaded', { mgy_match_id });
    }, [mixpanel, mgy_match_id]);

    return (
        <ScreenLayout>
            <Plant />
            <Books />
            <Windows />
            <Lights />
            {children}
        </ScreenLayout>
    );
};

export default AppContainer;
