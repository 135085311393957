export const Z_POSITIONS = {
    HAND_PHASE_PLAYER: 3,
    HAND_PHASE_CARDS: 3,
    HAND_PHASE_TABLE: 2,

    WIN_PHASE_WINNER: 6,
    WIN_PHASE_CARDS: 6,
    WIN_PHASE_OVERLAY: 5,
    WIN_PHASE_LOSER: 4,
    WIN_PHASE_TABLE: 2,
};
