import SeatsRenderer from './SeatsRenderer';
import MainPotRenderer from './MainPotRenderer';
import CardsRenderer from './CardsRenderer';
import WinnerRenderer from './WinnerRenderer';
import WinPhaseOverlay from './WinPhaseOverlay';

const GameRenderer = () => {
    return (
        <>
            <SeatsRenderer />
            <MainPotRenderer />
            <CardsRenderer />
            <WinnerRenderer />
            <WinPhaseOverlay />
        </>
    );
};

export default GameRenderer;
