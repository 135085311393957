import { Client } from 'boardgame.io/react';
import { Local, SocketIO } from 'boardgame.io/multiplayer';
// import Display from './Portals/Portals';
import { G, Poker } from '@magicyard/poker-game/src/Game';
import { EffectsBoardWrapper } from 'bgio-effects/react';
import BoardContainer from 'BoardContainer';

const urlParams = new URLSearchParams(window.location.search);
const serverURL =
    urlParams.get('serverURL') || process.env.REACT_APP_SERVER_URL;

const getMultiplayerMode = () => {
    if (process.env.REACT_APP_MULTIPLAYER_MODE?.toLowerCase() === 'local') {
        console.log('Using Local() multiplayer');
        return Local();
    }

    console.log(
        `Running using SocketIO() multiplayer with server: ${serverURL}`
    );
    return SocketIO({ server: serverURL });
};

const board = EffectsBoardWrapper<G>(BoardContainer, {
    // Delay passing the updated boardgame.io state to your board
    // until after the last effect has been triggered.
    updateStateAfterEffects: true,
});

const App = Client({
    game: Poker,
    board,
    debug: !window.location.search.includes('hidePanel'),
    multiplayer: getMultiplayerMode(),
});

export default App;
